/* eslint-disable no-empty-pattern */
import React, { useState, useEffect, useRef } from "react";
import kudaSuccessAnimation from '../../assets/animations/success-dark.json'
import Lottie from "react-lottie";

function SalesSuccessModal({
    onClose,
    message,
    doSomethingElse,
    children,
    btnText,
}) {
    const [state, setState] = useState({
        check: false,
        nick: "",
        isStopped: true,
    });
    const modalRef = useRef();

    function handleAnim() {
        setState({ ...state, isStopped: false });
    }

    function onClickOutside(e) {
        const element = e.target;

        if (modalRef.current && !modalRef.current.contains(element)) {
            e.preventDefault();
            e.stopPropagation();
            onClose();
        }
    }

    useEffect(() => {
        document.body.classList.add("backdrop-init");
        // closeModalOnClick(closeModal);
        document.body.addEventListener("click", onClickOutside);

        return () => {
            document.body.classList.remove("backdrop-init");
            document.body.removeEventListener("click", onClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const defaultOptions = {
        loop: false,
        autoplay: false,
        animationData: kudaSuccessAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    // modal-backdrop
    return (
        <div className={`modal`}>
            <div
                className={`modal modal-sendMoney small-modal  kuda-success--modal fadeIn animated `}
            >
                <div className="modal-content kudaFadeInUp animated">
                    <div className="kuda-modal--content">
                        <h4 className="modal-title  color-black text-center text-xbold margin-bottom--spacer">

                            {message && message !== "Done."
                                ? message : "Success!"}

                        </h4>
                        <div className="kuda-modal-illustration">
                            <Lottie
                                options={defaultOptions}
                                height={`100%`}
                                width={`100%`}
                                isStopped={state.isStopped}
                                eventListeners={[
                                    {
                                        eventName: "DOMLoaded",
                                        callback: (anim) => handleAnim(anim),
                                    },
                                ]}
                            />
                        </div>
                        {children}

                            <div className="flex justify-center mt-5">
                                <div
                                    className={`dark-cta kuda-cta kuda-cta-md`}
                                    onClick={doSomethingElse ? doSomethingElse : onClose}
                                >
                                    <span>{btnText || "Okay"}</span>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SalesSuccessModal;
