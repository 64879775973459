import React, { useState, useEffect } from "react";
import styled from "styled-components";
import NumberFormat from "react-number-format";

import SearchIcon from "../../../../assets/img/transfers-category.inline.svg"
import DownCaret from "../../../../assets/img/downCaret.inline.svg";
import { Img } from "react-image";


const PhoneNumberInput = (props) => {

    const [state, setState] = useState({
        headerTitle: "+234",
        flag: "https://flagcdn.com/ng.svg",
        initialItems: props.list,
        items: props.list,
        key: "",
        listOpen: false,
    });

    useEffect(() => {
        document.body.classList.add("modal-active");
        setTimeout(() => {
            if (state.listOpen) {
                window.addEventListener("click", close);
            } else {
                window.removeEventListener("click", close);
                document.body.classList.remove("modal-active");
            }
        }, 0);
        return () => {
            window.removeEventListener("click", close);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.listOpen]);

    const selectItem = (dial_code, stateKey, item) => {

        setState((prevState) => ({
            ...prevState,
            headerTitle: dial_code,
            flag: item.flag,
            listOpen: false,
            key: stateKey,
        }));
        props.onSelected(item);
    }

    function close() {
        setState((prevState) => ({
            ...prevState,
            listOpen: false,
        }));
    }

    function toggleList() {
        setState((prevState) => ({
            ...prevState,
            listOpen: !state.listOpen,
            items: props.list,
        }));
        return () => {
            document.querySelector('[type="search"]').focus();
        };
    }

    function filterList(event) {
        let items = state.initialItems;
        let newItems = items.filter((item) => {
            return item.dial_code.indexOf(event.target.value) !== -1;
        });
        setState((prevState) => ({
            ...prevState,
            items: newItems,
        }));
    }

    return (
        <PhoneInputStyled>
            <Container errorStyle={props.errorStyle}>
                <div className="phoneinput-country-code" onClick={() => {
                    // toggleList();
                }}>
                    <p>{state.headerTitle}</p>
                    <DownCaret />
                </div>

                <NumberFormat customInput={Input} {...props} />
                <Img src={state.flag} alt={state.code} className="phoneinput-nigeria-flag" />
            </Container>

            {state.listOpen && (
                <React.Fragment>
                    <div className="kuda-searchbox" onClick={(e) => e.stopPropagation()}>
                        <SearchIcon />
                        <input type="search" placeholder="Search Code" 
                        // onChange={filterList} 
                        />
                    </div>
                    <ul
                        className="kuda-dropdown-list beneficiary-dropdown-list"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {state.items.map((item) => (
                            <li
                                className="kuda-dropdown-item text-semi-bold"
                                key={item.name}
                                // onClick={() => selectItem(item.dial_code, item.name, item)}
                            >
                                {item.dial_code}

                            </li>
                        ))}
                        {state.items.length === 0 ? (
                            <li className="no-result">No results found</li>
                        ) : null}
                    </ul>
                </React.Fragment>
            )}
        </PhoneInputStyled>
    );
};
const PhoneInputStyled = styled.div`

    .kuda-dropdown-list{
    
        padding: 11px 0px 5px 10px;
    }
`;

const Container = styled.div`
    display: flex;
    border-radius: 5px;
    border: ${({ errorStyle }) =>
        errorStyle ? "1px solid var(--negativeColor)" : "1px solid #f4f4f4"};
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1) !important;
    height: 46px;
    width: 100%;
    margin-top: 10px;
    position: relative;

    .phoneinput-country-code {
        width: 81px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;

        border-radius: 5px;
        border: 1px solid #f4f4f4;
        background: #f9f9f9;
        p {
            color: #979797;
            font-weight: 600;
            line-height: 16px;
        }
    }
    .phoneinput-nigeria-flag {
        position: absolute;
        top: 50%;
        right: 24px;
        transform: translate(0, -50%);
        max-height: 16px;
    }
`;

const Input = styled.input`
    height: 100%;
    width: calc(100% - 81px);
    padding-left: 12px;
    color: #000;
    background: #fff;
    opacity: ${({ disabled }) => disabled && "0.5"};
    border: none;
    border-radius: 5px;
    font-family: 'Mulish';
    :focus {
        outline: none;
    }
    ::placeholder {
        color: #979797;
        font-size: 1rem;
        font-weight: 600;
        line-height: 16px;
    }
`;

export default PhoneNumberInput;
