import React, { useState, useRef } from "react";
import BackArrow from "../../../../../../../assets/img/back-arrow.inline.svg";
import Button from '../../../../general/Button'
import { validateEmail } from "../../../../../../../util/util";
import NumberFormat from "react-number-format";
import stateList from "../../json/state.json";
// import countryList from '../../json/country.json';
import countryList from '../../json/nigeria.json'
import StateDropdown from "../../../../general/StateDropdown";
import PhoneNumberInput from "../../../../general/phoneNumberInput";
import { FormLayoutWrapper, FormInnerWrapper, InnerWrapper, ModalWrapper, BusinessFormWrap } from "./formStyle";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, navigate } from "gatsby";
import SalesSuccessModal from "../../../../../../modals/salesSuccessModal";


const BusinessLoanForm = () => {
    const reCaptchaRef = useRef()

    const [inputValues, setInputValues] = useState({
        name: '',
        address: '',
        phoneNo: '',
        email: '',
        state: '',
        amount: null,
        accountNo: '',
        purpose: '',

        showEmailErrMsg: false,
        showAmtErrMsg: false,
    });

    const [modalObj, setModalObj] = useState({
        showModal: false,
        modalMessage: ""
    });

    const [stateInfo, setStateInfo] = useState({
        state: {
            info: "",
        }
    });
    
    const [phoneDataInfo, setPhoneDataInfo] = useState({
        dial_code: "+234",
        phoneLength: 10
    })

    const [validationErrObj, setValidationErrObj] = useState({
        emailErrMsg: "",
        amountErrMsg: "",
    });

    const [loading, setLoading] = useState(false);

    const handleUserInput = (event) => {

        const { value, name } = event.target;

        setInputValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    // const onCodeChange = (countryData) => {
    //     const { dial_code, phoneLength } = countryData;

    //     setPhoneDataInfo((prevState) => ({
    //         ...prevState,
    //         dial_code: dial_code,
    //         phoneLength: phoneLength
    //     }))

    //     setInputValues((prevState) => ({
    //         ...prevState,
    //         ["phoneNo"]: "",
    //     }));
    // }

    function chooseState(stateItem) {
        setStateInfo({
            ...stateInfo,
            state: {
                info: stateItem,
            }
        });

        setInputValues((prevState) => ({
            ...prevState,
            ["state"]: stateItem.name
        }))
    }

    function handleEmailValidation(email) {
        const error = validateEmail(email);

        setInputValues((prevState) => ({
            ...prevState,
            showEmailErrMsg: error ? true : false,
        }));

        setValidationErrObj((prev) => ({
            ...prev,
            emailErrMsg: error
        }));
        return error ? true : false;

    }

    const checkEmailValue = () => {
        const hasError = handleEmailValidation(inputValues.email);

        if (hasError) return;
    }

    const checkLength = (e) => {
        const { value } = e.target;
        let minAmount = 500000;
        let maxAmount = 10000000;

        const formattedVal = parseFloat(value.replace(/,/g, ''));

        if (isNaN(formattedVal) || formattedVal < minAmount || formattedVal > maxAmount) {
            const error = 'Amount should be in the range of 500,000 to 10,000,000';
            setInputValues((prevState) => ({
                ...prevState,
                showAmtErrMsg: true,
            }));
            setValidationErrObj((prev) => ({
                ...prev,
                amountErrMsg: error
            }));
        } else {
            setInputValues((prevState) => ({
                ...prevState,
                showAmtErrMsg: false,
            }));
            setValidationErrObj((prev) => ({
                ...prev,
                amountErrMsg: ""
            }));
        }
    }

    const handleSubmission = async (e) => {
        e.preventDefault();
        setLoading(true);

        const token = await reCaptchaRef.current.executeAsync();

        const payload = {
            companyName: inputValues.name,
            companyAddress: inputValues.address,
            contactPersonPhone: `${phoneDataInfo?.dial_code}${inputValues.phoneNo}`,
            contactPersonEmail: inputValues.email,
            contactPersonAccountNumber: inputValues.accountNo,
            state: inputValues.state,
            loanAmount: parseFloat(inputValues.amount.replace(/,/g, '')),
            loanPurpose: inputValues.purpose,
            captchaToken: token
        };

        const WebAppURL = `${process.env.WAITLIST_API}/api/Credit/acknowledge-business-loan`
        try {
            const response = await fetch(WebAppURL, {
                headers: {
                    Token: `${process.env.WAITLIST_TOKEN}`,
                    "Content-Type": 'application/json'
                },
                method: "POST",
                body: JSON.stringify(payload),
            })

            const data = await response.json();

            if (data.isSuccessful) {
                setLoading(false);

                setModalObj({
                    ...modalObj,
                    showModal: !modalObj.showModal,
                    modalMessage: `Your request has been sent to our sales team.`
                })
            }
        } catch (error) {
            console.log(error, 'error')
            return error
        }
    };

    const closeForm = () => {
        navigate(`/en-ng/business/loans`)

        setModalObj({
            ...modalObj,
            showModal: false,
            modalMessage: ""
        })
    }
    return (
        <BusinessFormWrap>
            <FormLayoutWrapper>
                <InnerWrapper height={modalObj?.showModal ? '607px' : 'auto'} showModal={modalObj?.showModal}>
                    <Link to="/en-ng/business/loans/">
                        <button
                            type="button"
                            className="back-to color-black"
                        >
                            <span className="backArrow">
                                <BackArrow />
                            </span>
                            Back
                        </button>
                    </Link>

                    {!modalObj?.showModal &&
                        <FormInnerWrapper>
                            <h2 className="kuda-page--title color-black text-center">Apply for a Loan</h2>

                            <div className="card-group">
                                <label className="card-label color-black">Company Name </label>
                                <div className="add-amount">
                                    <input
                                        className="amount-input normal-input"
                                        placeholder="Company's name"
                                        type="text"
                                        name="name"
                                        value={inputValues?.name}
                                        onChange={handleUserInput}
                                        maxLength={50}
                                        minLength={1}
                                    />
                                </div>
                            </div>

                            <div className="card-group">
                                <label className="card-label color-black">Company Address </label>
                                <div className="add-amount">
                                    <input
                                        className="amount-input normal-input"
                                        placeholder="Company's address"
                                        type="text"
                                        name="address"
                                        value={inputValues?.address}
                                        onChange={handleUserInput}
                                        maxLength={50}
                                        minLength={1}
                                    />
                                </div>
                            </div>

                            <div className="card-group mr-3 mt-25">
                                <label className="card-label color-black">Contact Person’s Phone Number </label>
                                <div>
                                    <PhoneNumberInput
                                        type="tel"
                                        name="phoneNo"
                                        placeholder="800 000 0000"
                                        allowNegative={false}
                                        onChange={handleUserInput}
                                        // onSelected={onCodeChange}
                                        decimalSeparator={false}
                                        value={inputValues.phoneNo}
                                        allowLeadingZeros
                                        maxLength={phoneDataInfo?.phoneLength}
                                        list={countryList}
                                    />

                                </div>
                            </div>

                            <div className="card-group">
                                <label className="card-label color-black">Contact Person’s Email </label>
                                <div className="add-amount">
                                    <input
                                        className="amount-input normal-input"
                                        placeholder="example@gmail.com"
                                        type="email"
                                        name="email"
                                        value={inputValues?.email}
                                        onChange={handleUserInput}
                                        minLength={10}
                                        onBlur={checkEmailValue}
                                    />
                                </div>

                                {inputValues?.showEmailErrMsg && (
                                    <p className="color-negative text-sm text-semi-bold mt-2">
                                        {validationErrObj?.emailErrMsg}
                                    </p>
                                )}
                            </div>

                            <div className="card-group">
                                <label className="card-label color-black">Contact Person’s Kuda Account Number (optional)</label>
                                <div className="add-amount">
                                    <NumberFormat
                                        className="amount-input normal-input text-normal"
                                        type="tel"
                                        placeholder="00123456789"
                                        value={inputValues?.accountNo}
                                        onChange={handleUserInput}
                                        name="accountNo"
                                        allowLeadingZeros
                                        maxLength={10}
                                    />
                                </div>
                            </div>

                            <div className="card-group">
                                <label className="card-label color-black">What State is your Company located?</label>
                                <div className="add-amount">
                                    <StateDropdown
                                        name={"state"}
                                        list={stateList}
                                        onSelect={chooseState}
                                        type="onboarding"
                                        user={stateInfo}
                                        headerTitle={
                                            inputValues?.state
                                        }
                                    />

                                </div>
                            </div>

                            <div className="card-group">
                                <label className="card-label color-black">Loan Amount Request </label>
                                <div className="add-amount">
                                    <span className="denomination">₦</span>
                                    <NumberFormat
                                        thousandSeparator={true}
                                        type="tel"
                                        name="amount"
                                        placeholder="0.00"
                                        value={inputValues?.amount}
                                        onChange={handleUserInput}
                                        onBlur={(e) => checkLength(e)}
                                        className="amount-input"
                                        allowNegative={false}
                                    />
                                </div>

                                {!inputValues?.showAmtErrMsg && <p className="text-sm text-semi-bold mt-2">Minimum is ₦500,000 and maximum is ₦10,000,000</p>}

                                {inputValues?.showAmtErrMsg && (
                                    <p className="color-negative text-sm text-semi-bold mt-2">
                                        {validationErrObj?.amountErrMsg}
                                    </p>
                                )}
                            </div>


                            <div className="card-group">
                                <label className="card-label color-black">Purpose of Loan Request</label>
                                <div className="add-amount">
                                    <input
                                        className="amount-input normal-input"
                                        type="text"
                                        placeholder="Type here"
                                        name="purpose"
                                        value={inputValues?.purpose}
                                        onChange={handleUserInput}
                                    />
                                </div>
                            </div>

                            <div className="">
                                <Button
                                    text="Send Request"
                                    width='100%'
                                    background="#000000"
                                    isSubmitting={loading}
                                    disabled={
                                        inputValues?.name === "" ||
                                        inputValues?.phoneNo === "" ||
                                        inputValues?.email === "" ||
                                        inputValues?.state === "" ||
                                        inputValues?.amount === null ||
                                        inputValues?.purpose === "" ||
                                        validationErrObj?.amountErrMsg !== "" || validationErrObj?.emailErrMsg !== ""
                                    }
                                    onButtonClick={(e) => handleSubmission(e)}
                                />

                            </div>

                            <ReCAPTCHA
                                sitekey={process.env.RECAPTCHA_SITEKEY}
                                size="invisible"
                                ref={reCaptchaRef}
                            />
                            <p style={{
                                fontSize: '11px',
                                fontWeight: 400,
                                lineHeight: '15px',
                                marginTop: '8px'
                            }}>Never share sensitive information (credit card numbers, social security numbers, passwords) through this form.</p>
                        </FormInnerWrapper>
                    }

                    {modalObj?.showModal && (
                        <ModalWrapper>
                            <SalesSuccessModal message="Sent" doSomethingElse={closeForm}>
                                <div className="password-set--message">
                                    <p className="text-center color-black">{modalObj?.modalMessage}</p>
                                </div>
                            </SalesSuccessModal>
                        </ModalWrapper>
                    )}

                </InnerWrapper>
            </FormLayoutWrapper>
        </BusinessFormWrap>
    )

}

export default BusinessLoanForm;