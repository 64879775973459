import styled from "styled-components";

export const BusinessFormWrap = styled.section`
width: calc(100% - 0px);
`

export const FormLayoutWrapper = styled.div`
width: 717px;
height: auto;
margin:0px auto;
display: flex;
flex-direction: column;
align-items: center;
position: relative;
font-family: 'Mulish';


.kuda-page--title {
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    font-family: 'Mulish';
    line-height: 34px; 
    margin-top: 34px;
    margin-bottom: 24px;
    text-align: center;
}

.back-to{
    background: none;
}

@media only screen and (max-width: 991px){
    width: 630px;
}
 @media only screen and (max-width: 767px){
   width: 100%;

   .back-to {
      left: 16px;
      top: 20px;
    } 
 }
 
 @media only screen and (max-width: 576px){
     width: 100%;
     border-radius: 0px;
     border: 0px;
     box-shadow: none;

    
     .back-to {
      position: relative;
      margin-bottom: 2rem;
      padding: 0px;
    }
 }
 @media only screen and (max-width: 425px){
  .kuda-page--title{
    font-size: 24px;
    margin-top: 0px;
    line-height: 25px;
  }

`

export const InnerWrapper = styled.div`
width: 80%;
margin-bottom: 30%;
height: ${(props) => props.height || 'auto'};
border-radius: 5px;
border: 1px solid rgba(219, 220, 224, 0.50);
background: var(--White, #FFF);
box-shadow: 15px 30px 40px 0px rgba(64, 25, 109, 0.07);
opacity: 1 !important;
position: relative;

.card-group {
    width: 100%;
    position: relative;

    .card-label{
    font-family: 'Mulish';
    }

    .amount-input{
      font-family: 'Mulish';
    }

    .add-amount .react-tel-input{
      display: flex;
    }
    .amount-input::placeholder {
      font-family: 'Mulish';
      color: rgba(151, 151, 151, 1);
    }
    .add-amount .react-tel-input .formInput{
      font-weight: 600;
    }
}

  @media only screen and (max-width: 767px){
    width: 90%;
  }

  @media only screen and (max-width: 576px){
    height: ${(props) => props.height && props.showModal ? '500px' : props.height};
  }
  @media only screen and (max-width: 425px){
    width: 100%;

    .card-group {

      .card-label{
      font-size: 13px;
      }

      .amount-input{
        font-size: 15px;
      }

      .amount-input::placeholder {
        font-size: 13px;
      }
    }

    .add-amount .react-tel-input .formInput{
      font-weight: 600;
    }
  }
`
export const FormInnerWrapper = styled.div`
  padding: 1.5rem 5.5rem 1.5rem 5.5rem;

  .react-tel-input .flag-dropdown {
    border: none;
    border-right: 1px solid rgba(244, 244, 244, 1) !important;
    background: rgba(244, 244, 244, 1);
  }

  @media only screen and (max-width: 767px){
    padding: 1.5rem 3rem;
  }

  @media only screen and (max-width: 425px){
    padding: 1.5rem 2.5rem;
  }

   @media only screen and (max-width: 375px){
    padding: 1.5rem 2rem;
  }

   @media only screen and (max-width: 320px){
    padding: 1.5rem;
  }
`

export const ModalWrapper = styled.div`
    padding: 0rem 4rem 0rem 4rem;
    margin-top: 6rem;

    .text-center{
      font-size: 15px;
      line-height: 18.83px;
      font-weight: 400;
      text-align: center;
    }

    .modal-title{
      font-size: 20px;
      font-weight: 800;
      font-family: 'Mulish';
    }
      
  @media only screen and (max-width: 576px){
  
    padding: 0rem 1.3rem;

    .modal-content{
      background: none;
      border: none;
      box-shadow: none;
      border-radius: 0px;
      padding: 0px;
    }
  }

   @media only screen and (max-width: 320px){
    .kuda-modal-illustration svg{
      width: -webkit-fill-available !important;
    }
  }
`